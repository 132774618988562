<template>
  <form>
    <b-form-select
      v-model="presenterKey"
      :options="presenters"
      :plain="true"
      @change="handleSelectChange"
      class="quick-jump mr-0 mr-sm-2 mb-3 mb-sm-0"
    >
      <template slot="first">
        <option :value="null" disabled>{{ i18n['quick-jump-presenter'].tcSelectAnotherPresenter }}</option>
      </template>
    </b-form-select>
  </form>
</template>

<script>
/* eslint-disable */
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'quick-jump',
  props: {
    i18n: {
      type: Object,
      default: () => {
        return {
          'quick-jump-presenter': {
            tcSelectAnotherPresenter: 'Select another presenter'
          }
        }
      }
    },
    presenterKeyProp: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      presenterKey: this.presenterKeyProp
    }
  },
  computed: {
    ...mapGetters({
      presenters: 'churchMinistry/presenterList'
    })
  },
  methods: {
    ...mapActions({
      setSelectedIndividualKey: 'user/setSelectedIndividualKey',
    }),
    async handleSelectChange() {
      await Promise.all([await this.setSelectedIndividualKey(this.presenterKey)]).then(
        () => {
          this.$emit('presenter_changed')
        }
      )
    }
  }
}
</script>

<style/>
